

  .dark-random:hover{
   
      transform: translateY(-5px);
    
  }
  
  

  .dark-customer:hover{
   
    transform: translateY(-5px);
  
  }
  
  

  .dark-review:hover{
   
    transform: translateY(-5px);
  
  }

 