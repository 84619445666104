/* body {
  background-color: #fff;
} */

.background-app {
  background-image: url('../assets/SVG/background.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* opacity: 50%; */
}


.div-searcher {
  transition: transform 0.2s ease-out;
}

.div-searcher:hover {
  transform: translateY(-5px);
}

.icon:hover {
  transform:scale(1.2);
}

.avatar_:hover {
  transform:scale(1.2);
}

.prev:hover {
  transform:scale(1.1);
}

.prev_:hover {
  transform:scale(1.1);
}

.draggable {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: lightblue;
  cursor: grab;
  user-select: none;
}