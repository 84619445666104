
  .login-button {
    background-color: #fdb88c;
    border: 1.6px solid black;
    font-weight: bold;
    color: black;
    transition: transform 0.2s ease-out;
    }

  .login-button:hover {
    background-color: #fff !important;
    color: black !important;
    transform: translateY(-5px);
  }

  .div-login {
      transition: transform 0.2s ease-out;
    }

  .div-login:hover{
      transform: translateX(-12px);
    }