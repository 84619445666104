

.confirm-values {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* font-weight: bolder; */
  color: #23405b;
  font-size: 1.1em;
}

.confirm-val {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  color: #23405b;
  font-size: 0.4;
}

.confirm-container {
  background-color: rgba(56, 82, 106, 0.5);
}

.confirm-cont {
  background-color: rgba(56, 82, 106, 0.7);
}




/*Selects                       */

/* Confirm data */
.setup-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  width: 90%;



}

.support-item {
  flex-basis: 15%;
  background-color: transparent;
  transition: all 0.55s ease-in-out;
  cursor: pointer;


}

.support-item-2 {
  flex-basis: 0%;
  margin-right: 0;


}

.middle-item {
  flex-basis: 33%;
  background-color: transparent;
  transition: all 0.55s ease-in-out;
  cursor: pointer;


}

.middle-item.selected {
  flex-basis: 35%;
  margin-right: 0;


}

.right-item {
  flex-basis: 15%;
  background-color: transparent;
  transition: all 0.55s ease-in-out;
  cursor: pointer;


}

.right-item.selected {
  flex-basis: 33%;
  margin-right: 0;


}

.random-light .ant-select-selector {
  background-color: #fff !important;
  border-radius: 3vh !important;
  /* border: none !important; */
  /* border: 1.8px solid #000 !important; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  color: #222326;
}

.random-dark .ant-select-selector {
  background-color: #2b2d31 !important;
  border-radius: 3vh !important;
  /* border: none !important; */
  border: 1px solid #00000060 !important;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  color: #c8c8c8 !important;
}

.random-dark:focus {
  background-color: #fff !important;
  /* border: none !important; */
  border: 1px solid #000 !important;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  color: red !important;
}


.random-dark .ant-select-selector-required::before {
  content: '*';
  margin-right: 4px;
  color: #e3e3e3 !important;
  /* Cambiar color aquí */
}

.random-dark .ant-select-selection-placeholder {
  color: #464545;
}

.search-dark {
  background-color: #8cadff;
  color: #000;
  font-weight: bold;
  border: 1.8px solid #000;
  border-radius: 2vh;
}

.search-dark:hover {
  background-color: #aca0fa !important;
  color: #000 !important;
  font-weight: bold;
  border: 1.8px solid #000;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.search-light {
  background-color: #a8c1f4;
  color: #000;
  font-weight: bold;
  border: 2px solid #000;
  border-radius: 2vh;
}

.search-light:hover {
  background-color: #aca0fa !important;
  color: #000 !important;
  font-weight: bold;
  border: 2px solid #000 !important;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.ant-btn:disabled {
  border-color: #000;
}

.save-random-light {
  background-color: #a8c1f4;
  color: #000;
  font-weight: bold;
  border: 1.8px solid #000;
  border-radius: 2vh;
}

.save-random-light:hover {
  background-color: #6edfc7 !important;
  color: #000 !important;
  font-weight: bold;
  border: 1.8px solid #000 !important;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.cancel-random-light {
  background-color: #fff !important;
  color: #000;
  font-weight: bold;
  border: 1.8px solid #000;
  border-radius: 2vh;
}

.cancel-random-light:hover {
  background-color: #ef83ae !important;
  color: #000 !important;
  font-weight: bold;
  border: 1.8px solid #000 !important;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.save-random-dark {
  background-color: #a8c1f4;
  color: #000;
  font-weight: bold;
  border: 1.8px solid #000;
  border-radius: 2vh;
  transition: all 0.25s ease-in-out;
}

.save-random-dark:hover {
  background-color: #6edfc7 !important;
  color: #000 !important;
  font-weight: bold;
  border: 1.8px solid #000 !important;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.cancel-random-dark {
  background-color: #393b3f !important;
  color: #fff;
  font-weight: bold;
  border: 1.8px solid #000;
  transition: all 0.25s ease-in-out;
  border-radius: 2vh;
}

.cancel-random-dark:hover {
  background-color: #ef83ae !important;
  color: #000 !important;
  font-weight: bold;
  border: 1.8px solid #000 !important;
  border-radius: 1.5vh;
  transform: translateY(-5px);
}

.custom-dropdown {
  background-color: #333437;
  color: #fff;
}



.input-amount-dark {
  background-color: #2b2d31;
  border: 1px solid #000;
}


.input-amount-dark .ant-input-number-input {
  color: #e3e3e3;

}

.input-amount-dark .ant-input-number-handler-wrap {
  background-color: #222326;

}

.input-amount-dark .ant-input-number-handler-wrap {
  border-color: #222326 !important;
}

.my-table .ant-table-thead > tr > th {
  color: #aaa !important; /* Text color for table header */
  background-color: #292A2E !important;
}



.my-table .ant-table-tbody > tr > td {
  color: #aaa !important; /* Text color for table cells */
  background-color: #292a2e !important;
  border-color: #44444480 !important;
}

.my-table .ant-table-container {
  border-color: #000 !important; /* Border color for the scrollbar */
  background-color: #2B2C31 !important;
}
/* Estilos de la tabla */



.light-table .ant-table-thead > tr > th {
  /* background-color: #222326 !important; */
  color: #aaa !important;
  font-weight: 400 !important;
}

/* Estilos de las celdas */
.my-table .ant-table-cell {
  color: #AAA !important;
  /* border-top: 1.5px solid #000 !important; */
  border-bottom: 1px solid #000 !important;
   background-color: #292a2e !important;
}


/* Estilos del scroll */
.my-table .ant-table-body::-webkit-scrollbar {
  width: 0px; /* Ancho del scroll */
  height: 0px;
}

.my-table .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #2A2A2E; /* Color del scroll */
  border-radius: 2vh;
}


.my-table .ant-table-body::-webkit-scrollbar-track {
  background: #2B2D31 !important;
  /* Color de fondo de la pista de la barra de desplazamiento */
}




/* Confirm data */
.confirm-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  width: 90%;


}

.confirm-item {
  flex-basis: 10%;
  background-color: transparent;
  transition: all 0.65s ease-in-out;
  cursor: pointer;


}

.confirm-item.selected {
  flex-basis: 45%;
  margin-right: 0;

}


.confirm-rectangle {
  border: 2px solid #000;
  background-color: #fff;
  width: 40%;
  height: 75%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2vw;
  border-top-left-radius: 2vw;
  transition: all 0.25s ease-in-out;
  /* clip-path: confirm-circle(100% at top); */
}


.confir-cancel-button {
  border: 1.8px solid #000;
  border-radius: 0.5vw;
  background-color: #fff;
  font-weight: bold;
}

.confir-start-button {
  border: 1.8px solid #000;
  border-radius: 0.5vw;
  background-color: #a8c1f4;
  font-weight: bold;
}

.confir-review-button {
  border: 1.8px solid #000;
  border-radius: 0.4vw;
  background-color: #f5f5f5;
  font-weight: bold;
}

.confir-review-button:hover {
  border: 1.8px solid #000 !important;
  color: #000 !important;
  border-radius: 0.4vw;
  background-color: #fff !important;
  font-weight: bold;
}

.confir-cancel-button:hover {
  border: 1.8px solid #000 !important;
  border-radius: 1.5vw;
  color: #000 !important;
  background-color: #ef83ae !important;
  font-weight: bold;
  transform: translateY(-5px);
}

.confir-start-button:hover {
  border: 1.8px solid #000 !important;
  border-radius: 1.5vw;
  color: #000 !important;
  background-color: #6edfc7 !important;
  font-weight: bold;
  transform: translateY(-5px);
}

.confirm-circle:hover {
  background-color: #fff !important;
  /* transform: translateY(-5px); */
  transform: rotate(360deg);
}

.confir-start-button-dark {
  border: 1.8px solid #000;
  border-radius: 0.5vw;
  background-color: #a8c1f4;
  font-weight: bold;
}

.confir-review-button-dark {
  border: 1.8px solid #000;
  border-radius: 0.4vw;
  background-color: #c3c3c3;
  font-weight: bold;
}

.confir-cancel-button-dark {
  border: 1.8px solid #000;
  border-radius: 0.5vw;
  background-color: #393b3f;
  font-weight: bold;
}

.confir-cancel-button-dark:hover {
  border: 1.8px solid #000 !important;
  border-radius: 1.5vw;
  color: #000 !important;
  background-color: #ef83ae !important;
  font-weight: bold;
  transform: translateY(-5px);
}

.confir-review-button-dark:hover {
  border: 1.8px solid #000 !important;
  color: #000 !important;
  border-radius: 0.4vw;
  background-color: #f3f3f3 !important;
  font-weight: bold;
}

.finish-cancel-button:hover {
  border: 1.8px solid #000 !important;
  color: #000 !important;
  border-radius: 0.4vw;
  background-color: #f3f3f3 !important;
  font-weight: bold;
  transform: translateY(-5px);

}

.view-button:hover {
  border: 1.5px solid #000 !important;
  color: #000 !important;
  border-radius: 0.4vw;
  background-color: #a8c1f4 !important;
  font-weight: bold;
  transform: translateY(-5px);

}

.custom-text-color .ant-calendar-range-picker-separator {
  color: red !important; /* Cambia el color a rojo */
}

.custom-text-color .ant-calendar-range-picker-input {
  color: red !important; /* Cambia el color a rojo */
}


.scrollable-div {
  /* height: 40vh; */
  /* Altura deseada */
  overflow: auto;
  /* Añade una barra de desplazamiento si el contenido excede la altura */
}

.scrollable-div::-webkit-scrollbar {
  width: 10px;
  /* Anchura de la barra de desplazamiento */
}

.scrollable-div::-webkit-scrollbar-track {
  background: transparent;
  /* Color de fondo de la pista de la barra de desplazamiento */
}

.dark-scroll::-webkit-scrollbar {
  width: 15px;
  /* Anchura de la barra de desplazamiento */
}

.dark-scroll::-webkit-scrollbar-track {
  background: #2b2d31;
  /* Color de fondo de la pista de la barra de desplazamiento */
}

.dark-scroll::-webkit-scrollbar-thumb {
  background: #27292c;
  /* Color del pulgar de la barra de desplazamiento */
}

.scroll-blue::-webkit-scrollbar-thumb {
  background: #a8c1f4;
  /* Color del pulgar de la barra de desplazamiento */
}

.scroll-pink::-webkit-scrollbar-thumb {
  background: #ef83ae;
  /* Color del pulgar de la barra de desplazamiento */
}


.scroll-green::-webkit-scrollbar-thumb {
  background: #6edfc7;
  /* Color del pulgar de la barra de desplazamiento */
}


.scrollable-div::-webkit-scrollbar-thumb:hover {
  background: #8cadff;
  /* Color del pulgar de la barra de desplazamiento al pasar el cursor sobre él */
}



@keyframes loading {
  0% { background-position: left; }
  25% { background-position: right 0; }
  50% { background-position: right 50%; }
  75% { background-position: right 100%; }
  100% { background-position: left; }
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, #fff 0%, #f9afcd 0%, #f9afcd 25%, #aca0fa 25%, #aca0fa 50%, #6edfc7 50%, #6edfc7 75%, #fff 75%);
  background-size: 400% 100%;
  color: transparent;
  /* -webkit-background-clip: text; */
  animation: loading 5s linear infinite;
  cursor: default;
}

.loading-text-dark {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, #aaa 0%, #946D7F 0%, #946D7F 25%, #635E8A 25%, #635E8A 50%, #6E8F8A 50%, #6E8F8A 75%, #aaa 75%);
  background-size: 400% 100%;
  color: transparent;
  /* -webkit-background-clip: text; */
  animation: loading 5s linear infinite;
  cursor: default;
}

/* Estilos de la tabla */
.my-table-revship {
  background-color: #2B2D31 !important;
  border-radius: 0vh !important;
  /* border-radius: 30% !important; */
}


/* Estilos del encabezado */
.my-table-revship .ant-table-thead > tr > th {
  background-color: #292A2E !important;
  border-radius: 0vh !important;
  color: #aaa !important;
  
}

/* Estilos de las celdas */
.my-table-revship .ant-table-cell {
  color: #AAA !important;
  /* border-top: 1.5px solid #000 !important; */
  border-bottom: 1px solid #00000060 !important;
   background-color: #2B2D31 !important;
}

/* Estilos del scroll */
.my-table-revship  .ant-table-body::-webkit-scrollbar {
  width: 15px; /* Ancho del scroll */
  height: 15px;
}

.my-table-revship .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #666C7A80; /* Color del scroll */
  border-radius: 2vh;
}


.my-table-revship  .ant-table-body::-webkit-scrollbar-track {
  background: #2B2D31 !important;
  /* Color de fondo de la pista de la barra de desplazamiento */
}

.my-table-revship .ant-table-container {
  border-color: #000 !important; /* Border color for the scrollbar */
  background-color: #292a2e !important;
}




/*  */
